import React from "react";
import WeCoverSection from "../../components/Projects/WeCover/wecover";
import Layout from "../../components/UI/layout";
import SEO from "../../components/UI/seo";
import { graphql } from "gatsby";

const weCoverPage = () => (    
    <Layout>
        <SEO title="Codeicus | Wecover" />
        <WeCoverSection/>
    </Layout>
)

export default weCoverPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;